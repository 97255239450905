import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { isPrivate } from '@wix/communities-forum-client-commons';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import { ImgBackground } from '@wix/communities-image-lib';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import Link from '../link/internal-link';
import FollowButton from '../follow-button/follow-button';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import FollowNotification from '../follow-notification';
import { ViewCountIcon } from '../icons/view-count-icon';
import { CommentIconSmall } from '../icons/comment-icon-small';
import CounterNumber from '../counter-number';
import { Themes } from '../../constants/themes';
import { PostBreakpoints, useForumWidthContext } from '../responsive-listener';

const Counts = (props) => {
  const {
    showViewCount,
    category,
    t,
    shouldHide,
    hasSubcategories,
    isSubcategory,
    isViewsCountEnabled,
    styles,
    contentFontClassName,
  } = props;

  return (
    <>
      <div
        className={classNames(styles.item, styles.counts, contentFontClassName, {
          [styles.hidden]: shouldHide || !isViewsCountEnabled,
          [styles.withSubcategory]: hasSubcategories,
          [styles.subcategory]: isSubcategory,
        })}
      >
        <ViewCountIcon title={t('subcategory-list-item.views')} className="forum-icon-fill" />
        <span>
          {showViewCount ? (
            <CounterNumber entityId={category._id} viewCount />
          ) : (
            <span>&nbsp;</span>
          )}
        </span>
      </div>
      <div
        className={classNames(styles.item, styles.counts, contentFontClassName, {
          [styles.hidden]: shouldHide,
          [styles.withSubcategory]: hasSubcategories,
          [styles.subcategory]: isSubcategory,
        })}
        data-hook="category-list-item__total-posts"
      >
        <CommentIconSmall
          title={t('subcategory-list-item.posts', 'subcategories-list-item__total-posts')}
          className="forum-icon-fill"
        />
        <span>
          <CounterNumber initialValue={category.totalPosts} entityId={category._id} totalPosts />
        </span>
      </div>
    </>
  );
};

const CategoryListClassicItem = ({
  category,
  hasSubcategories,
  isSubcategory,
  showCounts,
  isViewsCountEnabled,
  can,
  styles,
  startLoadingCategory,
  hasCategoryPP,
  titleFontClassName,
  contentFontClassName,
}) => {
  const hasImage = category.coverType === COVER_TYPE_IMAGE;
  const width = useForumWidthContext();
  const hideFollowButtonInActions = width > PostBreakpoints.xs;
  const shouldShowMoreButton = can('edit', 'category', category);

  const getIcon = () => {
    if (isPrivate(category)) {
      const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
      return (
        <span className={styles.categoryBadgeWrapper}>
          <Icon className="forum-icon-fill" />
        </span>
      );
    }
  };

  return (
    <>
      <div
        className={classNames(styles.item, styles.imgContainer, {
          [styles.withSubcategory]: hasSubcategories,
          [styles.subcategory]: isSubcategory,
          [styles.hidden]: !hasImage || isSubcategory,
        })}
      >
        {hasImage && (
          <Link
            className={classNames('forum-text-color', 'forum-link-hover-color')}
            to={`/${category.slug}`}
            onClick={() => {
              startLoadingCategory(category._id);
            }}
          >
            <ImgBackground
              className={styles.image}
              src={category.cover}
              width={100}
              height={60}
              aria-labelledby={`cl-${category._id}`}
            />
          </Link>
        )}
      </div>
      <div
        className={classNames(styles.item, styles.content, 'forum-text-color', {
          [styles.contentWithoutImage]: !hasImage || isSubcategory,
          [styles.contentWithoutViewCounts]: !isViewsCountEnabled && showCounts,
          [styles.contentWithoutCounts]: !showCounts,
          [styles.withSubcategory]: hasSubcategories,
          [styles.subcategory]: isSubcategory,
        })}
      >
        <Link
          className={classNames('forum-text-color', 'forum-link-hover-color')}
          to={`/${category.slug}`}
          onClick={() => {
            startLoadingCategory(category._id);
          }}
          data-hook={isSubcategory ? 'subcategory-list-item__link' : 'category-list-item__link'}
        >
          <h2
            className={classNames(titleFontClassName, styles.title)}
            data-hook="category-list-item__title"
            id={`cl-${category._id}`}
          >
            {category.label}
            {getIcon()}
          </h2>
        </Link>
        <p
          className={classNames(contentFontClassName, styles.description)}
          data-hook="category-list-item__description"
        >
          {category.description}
        </p>
      </div>
      <Counts
        isViewsCountEnabled={isViewsCountEnabled}
        category={category}
        t={(text) => text}
        showViewCount={true}
        shouldHide={!showCounts}
        hasSubcategories={hasSubcategories}
        isSubcategory={isSubcategory}
        styles={styles}
        contentFontClassName={contentFontClassName}
      />
      <div
        className={classNames(styles.item, styles.follow, {
          [styles.withSubcategory]: hasSubcategories,
          [styles.subcategory]: isSubcategory,
        })}
      >
        {hideFollowButtonInActions && (
          <FollowButton
            category={category}
            className={styles.followButton}
            data-hook="category-list-item__follow-button"
            size={isSubcategory ? FollowButton.SIZE_SMALL : undefined}
          >
            <FollowNotification isFollowing={category.isSubscribed} />
          </FollowButton>
        )}
        {shouldShowMoreButton && (
          <MoreButton
            size={MoreButton.SIZE_SMALL}
            data-hook="category-list-item__category-actions"
            theme={Themes.BlackAndWhite}
          >
            <CategoryActions
              showCategoryActions
              hideFollowButton={hideFollowButtonInActions}
              category={category}
              theme={Themes.BlackAndWhite}
            />
          </MoreButton>
        )}
      </div>
    </>
  );
};

CategoryListClassicItem.propTypes = {
  category: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  can: PropTypes.func,
  t: PropTypes.func,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
};

const mapRuntimeToProps = (state, { category }, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  startLoadingCategory: actions.startLoadingCategory,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.PAID_PLANS, REDUCERS.BASIC_PARAMS]),
  withTranslate,
  withFontClassName,
  withPermissions,
)(CategoryListClassicItem);
