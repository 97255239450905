import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import CategoryPage from '../category-page';
import CategoriesPage from '../categories-page';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';
import { flowRight } from 'lodash';
import { FeedType, getFeedType } from '../header-navigation';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';

const HomePage = ({
  isMainPageEnabled,
  feedType,
  currentSiteMemberId,
  currentLocationPath,
  currentRouterPath,
  ...props
}) => {
  if (feedType === FeedType.MyPosts && currentSiteMemberId) {
    return <CategoryPage siteMemberId={currentSiteMemberId} {...props} />;
  }

  if (feedType === FeedType.Categories || (!feedType && isMainPageEnabled)) {
    return <CategoriesPage {...props} />;
  }

  if (feedType === FeedType.Posts || (!feedType && !isMainPageEnabled)) {
    return <CategoryPage {...props} />;
  }

  return <CategoriesPage {...props} />;
};

HomePage.propTypes = {
  isMainPageEnabled: PropTypes.bool.isRequired,
  feedType: PropTypes.string,
  currentSiteMemberId: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isMainPageEnabled: getIsMainPageEnabled(state, host.style),
  feedType: getFeedType(state),
  currentSiteMemberId: getCurrentUser(state)?.siteMemberId,
});

export default flowRight(connect(mapRuntimeToProps))(HomePage);
