import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import CategoryListItem from './category-list-classic-item-desktop';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getIsShowSubcategoriesEnabled } from '../../selectors/app-settings-selectors';
import styles from './category-list-classic-desktop.scss';

import { useForumWidthContext, PostBreakpoints } from '../responsive-listener';
import { HorizontalSeparator } from '../separator/separator';

const ListItemFragment = ({
  category,
  subcategories,
  isShowSubcategoriesEnabled,
  showCounts,
  isViewsCountEnabled,
}) => {
  return (
    <li
      className={classNames(
        styles.itemsContainer,
        'forum-card-border-color',
        'forum-card-background-color',
        'forum-text-color',
        'forum-content-medium-font',
      )}
      data-hook="category-list-item"
    >
      <CategoryListItem
        category={category}
        hasSubcategories={isShowSubcategoriesEnabled && (subcategories ?? []).length > 0}
        showCounts={showCounts}
        isViewsCountEnabled={isViewsCountEnabled}
        styles={styles}
      />
      {isShowSubcategoriesEnabled &&
        subcategories.length > 0 &&
        subcategories.map((subcategory) => (
          <React.Fragment key={subcategory._id}>
            <span className={styles.dividerContainer}>
              <HorizontalSeparator className={styles.divider} />
            </span>
            <CategoryListItem
              category={subcategory}
              isSubcategory
              showCounts={showCounts}
              isViewsCountEnabled={isViewsCountEnabled}
              styles={styles}
            />
          </React.Fragment>
        ))}
    </li>
  );
};

const mapRuntimeToProps = (state, { category }, actions, host) => ({
  subcategories: getSubcategories(state, category._id),
  isShowSubcategoriesEnabled: getIsShowSubcategoriesEnabled(state, host.style),
});

const ListItem = flowRight(connect(mapRuntimeToProps, [REDUCERS.CATEGORIES]))(ListItemFragment);

const CategoryListClassicDesktop = ({ categories, isViewsCountEnabled }) => {
  const width = useForumWidthContext();
  const showCounts = width > PostBreakpoints.md;
  return (
    <ul
      className={classNames(styles.gridContainer, {
        [styles.gridContainerWithoutViewCount]: !showCounts || !isViewsCountEnabled,
      })}
      data-hook="category-list-classic"
    >
      {categories.map((category) => (
        <ListItem
          key={category._id}
          category={category}
          isViewsCountEnabled={isViewsCountEnabled}
          showCounts={showCounts}
        />
      ))}
    </ul>
  );
};

CategoryListClassicDesktop.propTypes = {
  categories: PropTypes.array.isRequired,
  isViewsCountEnabled: PropTypes.bool,
};

export default CategoryListClassicDesktop;
